/*** Mobile Screens ***/
#sidebar{
  width: 100%;
  margin-bottom: 40px;
  float: right;
  position: relative;

  .Level-1{
    display: none;
    ul{
      clear: both;
      float: left;
      width: 100%;
    }
  }

  button{
    position: absolute;
    top: 0px;
    border: none;
    outline: none !important;
    right: 0px;
    cursor: pointer;
    width: 30px;
    height: 28px;
    @include transition($t1);
    background-color: transparent;
    font-size: 1.3em;
    color: $textDark;
    &:hover{
      color: $themeColor;
    }
  }
  .sidebar-heading{
    display: block;
    float: left;
    clear: both;
    width: 80%;
    a{
      padding: 5px 30px 5px 10px;
      display: block;
      text-decoration: none;
      color: #165844;
      font-family: 'AmaticBold';
    font-family: "macho",sans-serif;
      font-size: 3em;
    }
  }
  ul{

      font-size: 1.2em;
    *{
      font-size: 1em;
    }
    ul{
      display: none;

      li{
        a{
          /*** Level Indentation ***/
        }
      }

      ul{
        li{
          a{
            /*** Level Indentation ***/
          }
        }
      }
    }
    li{
      clear: both;
      float: left;
      width: 100%;
      position: relative;
      a{
        display: block;
        padding: 5px 30px 5px 10px;
        text-decoration: none;
        position: relative;
        @include transition($t1);
        background-color: rgba(0,0,0,0.0);

        &:before{
          position: absolute;
          display: block;
          content: ''; 
          bottom: 0px;
          left: 10px;
          opacity: 0;
          @include transition($t1);
          width: 0px;
          height: 3px;
          background-color: #ffdc1b;
        }

        span{
          color: #484749;
          @include transition($t1);
        }
        &:hover span{
        }
        &.currentPage:before{
          opacity: 1;
          width: 50px;
        }
        &:hover{
         &:before{
            opacity: 1;
            width: 50px;
          }
        }
      }
    }
  }
}

#sidebar-inner{
  overflow: hidden;
  float: left;
  clear: both;
  width: 100%;
  padding-right: 30px;
}

/*** Desktop Screens ***/
@media all and (max-width: 768px) {
  #sidebar{
    display: none;
  }
}
@media all and (min-width: 768px) {
  #sidebar{
    .Level-1{
      display: block !important;
      margin-top: 0% !important;
    }
    ul{
      ul{
        display: none;
      }
    }
    .mainToggle:first-of-type{
      display: none  !important;
    }
  }
  .hideSideBarToggle{
    display: none;
  }
}
